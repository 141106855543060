import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Slider.css";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import OfferShort from '../offerShort/OfferShort';

const Slider = () => {
    return (

        <div>

            <OfferShort></OfferShort>
   
        <Carousel className="custom-carousel-slider" >
            <Carousel.Item>
                <img

                    className="slider d-block w-100"
                    src="/images/Slliderone.jpg"
                    alt="Lazer Kesim "
                />


                <Carousel.Caption className='sliderüstüyazı d-flex'>
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        <p className='fw-bold w3-animate-top'>
                            LAZER KESİM
                        </p>
                    </div>
                </Carousel.Caption>

                <Carousel.Caption className='sliderüstüyazı2 d-flex'>
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        <p className='fw-bold'>DKP-HRP-Siyah Sac-Paslanmaz-Galvaniz</p>
                        <div className='SliderUrunlerButtonVeYazi px-1' >
                            <Link to='/uretimlerimiz'>

                                <Button
                                    className='buttonSliderUrunler mt-2  fw-bold float-right'
                                    style={{ borderRadius: "10px 10px 10px 10px" }}
                                >
                                    <span className='fw-bold' style={{ fontSize: "15px", color: "white" }}>Üretimlerimiz</span>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img

                    className="slider d-block w-100 "
                    src="/images/bukumslider.png"
                    alt="Cnc Sac Bukum"
                />
                <Carousel.Caption className='sliderüstüyazı d-flex'>
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        <p className='yazi-altsatira fw-bold w3-animate-top'>
                            SAC BÜKÜM
                        </p>
                    </div>
                </Carousel.Caption>

                <Carousel.Caption className='sliderüstüyazı2 d-flex'>
                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                        DKP - HRP - Siyah Sac - Paslanmaz - Galvaniz
                        <div className='SliderUrunlerButtonVeYazi px-1' >
                            <div className='buttonlar d-flex w-'>
                            <Link to='/uretimlerimiz'>


                                <Button
                                    variant="primary"
                                    className='buttonSliderUrunler mt-2  w-100 px-3 fw-bold float-right'
                                    style={{ borderRadius: "10px 10px 10px 10px" }}

                                >

                                    <span className='fw-bold' style={{ fontSize: "15px", color: "white" }}>Üretimlerimiz</span>

                                </Button>
                            </Link>

                            </div>


                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>

        </div>
    )
}

export default Slider;