import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./IsOrtaklarımız.css";
import {  Pagination } from "swiper/modules";
import Card from 'react-bootstrap/Card';
export const IsOrtaklarımız = () => {
  return (
    <div className='mt-3 projelerdiv p-3 mb-4'  >

      <h3 className='AmacText container  px-5  p-3' style={{ color: "rgba(242, 38, 19, 1)" }}>
        PROJE ORTAKLARIMIZ
      </h3>
      <Swiper

        slidesPerView={1}
        spaceBetween={2}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 2.3,
            spaceBetween: 10,
          },

          "@0.60": {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3.2,
            spaceBetween: 30,
          },
          "@1.50": {
            slidesPerView: 4.2,
            spaceBetween: 10,
          },

        }}
        modules={[Pagination]}
        className="mySwiper p-3"
      >




        <SwiperSlide style={{ backgroundColor: "white", maxHeight: "15rem", width: "15rem", borderRadius: "20px" }} className="">

          <Card className="cardprojeler" style={{ maxHeight: "11rem", border: "none" }} >


            <Card.Img style={{ objectFit: "cover", width: "100%", height: "10rem", borderRadius: "0px" }} variant="top" src="/images/ortak5.png" />


          </Card>

        </SwiperSlide>

        <SwiperSlide style={{ backgroundColor: "white", maxHeight: "15rem", width: "17rem", borderRadius: "20px" }} className="">

<Card className="cardprojeler" style={{ maxHeight: "11rem", border: "none" }} >


  <Card.Img style={{ maxHeight: "15rem", objectFit: "cover", width: "100%", height: "10rem", borderRadius: "0px" }} variant="top" src="/images/ortak4.png" />


</Card>

</SwiperSlide>

<SwiperSlide style={{ backgroundColor: "transparent", maxHeight: "11rem", width: "17rem", borderRadius: "20px" }} className="">

<Card className="cardprojeler" style={{ maxHeight: "11rem", border: "none" }} >


  <Card.Img style={{ maxHeight: "18rem", objectFit: "cover", width: "100%", height: "10rem", borderRadius: "0px" }} variant="top" src="/images/ortak2.png" />


</Card>

</SwiperSlide>

        <SwiperSlide style={{ backgroundColor: "transparent", maxHeight: "11rem", width: "17rem", borderRadius: "20px" }} className="">

          <Card className="cardprojeler" style={{ maxHeight: "11rem", border: "none", }} >


            <Card.Img style={{ maxHeight: "18rem", objectFit: "cover", width: "100%", height: "10rem", borderRadius: "0px" }} variant="top" src="/images/ortak1.png" />


          </Card>

        </SwiperSlide>
      

        <SwiperSlide style={{ backgroundColor: "transparent", maxHeight: "11rem", width: "17rem", borderRadius: "20px" }} className="">

          <Card className="cardprojeler" style={{ maxHeight: "11rem", border: "none" }} >


            <Card.Img style={{ maxHeight: "18rem", objectFit: "cover", width: "100%", height: "10rem", borderRadius: "0px" }} variant="top" src="/images/ortak3.png" />


          </Card>

        </SwiperSlide>

      

      </Swiper>

    </div>
  )
}
