import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {  Pagination } from "swiper/modules";
import Card from 'react-bootstrap/Card';

const ProfilKesimFotolari = () => {
    return (
        <div className='urunlerdivLazerKesim'   >


            <h3 className=' container  px-5 mt-3 ' style={{ color: "rgba(1, 1, 122, 1)", whiteSpace: "nowrap" }} >
                PROFİL KESİMLERİMİZ
            </h3>

            <Swiper
                style={{backgroundColor:"transparent"}}
                slidesPerView={1}
                spaceBetween={2}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    "@0.00": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },

                    "@0.60": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },
                    "@0.75": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },
                    "@1.00": {
                        slidesPerView: 4.5,
                        spaceBetween: 30,
                    },
                   
                    "@1.50": {
                        slidesPerView: 4.5,
                        spaceBetween: 20,
                    },
                    
                 
                  


                 

                }}
                modules={[Pagination]}
                className="mySwiper"
            >


          <SwiperSlide className="swiperurunler mt-5" >


                    <Card className="cardUrunlerimiz">
                        <Card.Img className='swiperImage' variant="top" src="/images/profil1.jpg"  style={{backgroundColor:"transparent"}}/>


                    </Card>

                </SwiperSlide>


          <SwiperSlide className="swiperurunler mt-5" >

                    <Card className="cardUrunlerimiz" style={{backgroundColor:"transparent"}} >
                        <Card.Img className='swiperImage' variant="top" src="/images/profilkesim1.png" />

                    </Card>

                </SwiperSlide>



          <SwiperSlide className="swiperurunler mt-5" >

                    <Card className="cardUrunlerimiz"   >
                        <Card.Img className='swiperImage'  variant="top" src="/images/profilkesim2.png" />

                    </Card>

                </SwiperSlide>
          <SwiperSlide className="swiperurunler mt-5" >

                    <Card className="cardUrunlerimiz" >
                        <Card.Img className='swiperImage' variant="top" src="/images/profilBanner.png" />


                    </Card>

                </SwiperSlide>

          <SwiperSlide className="swiperurunler mt-5" >

                    <Card className="cardUrunlerimiz" >
                        <Card.Img className='swiperImage' variant="top" src="/images/3234.png" />


                    </Card>

                </SwiperSlide>



            </Swiper>

        </div>
    )
}

export default ProfilKesimFotolari;