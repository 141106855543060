import React from 'react'
import {Outlet} from "react-router-dom"
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
const Layout = () => {
  return (
    <div>
        <Header /> 

        <div>
              <Outlet />

              <Footer/>
        </div>
    </div>
  )
}

export default Layout