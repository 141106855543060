import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Hizmetlerimiz.css"
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import { Helmet } from 'react-helmet';
const Hizmetlerimiz = ({ hizmetlerimiz }) => {
  return (
    <>

      <Helmet>
        <title>HİZMETLERİMİZ - cnclazermetalkesim.com</title>
      </Helmet>
      <Carousel className="custom-carousel-slider1" indicators={false}>
        <Carousel.Item>
          <img alt='hizmetlerimiz' className='w-100' src='https://i.hizliresim.com/ce3evzl.png'></img>
          <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                Hizmetlerimiz
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container className='p-5'>
        <Row>

          <BreadCrumb />
          <Col sm={4} className='mb-5'>

            <Link to="/hizmetlerimiz/cnclazerkesim" style={{ textDecoration: "none" }}>
              <Card style={{ border: "none" }} className='cardHizmetlerimiz '>
                <Card.Img style={{ width: "100%", borderRadius: "10px" }} variant="top" src="/images/cnclazerkesim.png" />
                <Card.Body style={{ marginTop: "-25%", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "1px" }}>
                  <h3 className='text-center' style={{ color: "white" }}>CNC LAZER KESİM</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col sm={4} className='mb-5'>

            <Link to="/hizmetlerimiz/cncbukum" style={{ textDecoration: "none" }}>
              <Card style={{ border: "none" }} className='cardHizmetlerimiz '>
                <Card.Img style={{ width: "100%", borderRadius: "10px" }} variant="top" src="/images/cncbukum.png" />
                <Card.Body style={{ marginTop: "-25%", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "1px" }}>
                  <h3 className='text-center' style={{ color: "white" }}>CNC BÜKÜM</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col sm={4} className='mb-5'>
            <Link to="/hizmetlerimiz/profilkesim" style={{ textDecoration: "none" }}>
              <Card style={{ border: "none" }} className='cardHizmetlerimiz '>
                <Card.Img style={{ width: "100%", borderRadius: "10px" }} variant="top" src="/images/profilkesim.png" />
                <Card.Body style={{ marginTop: "-25%", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "1px" }}>

                  <h3 className='text-center' style={{ color: "white" }}>PROFİL KESİM</h3>


                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>

      </Container>
    </>

  )
}

export default Hizmetlerimiz;