import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
const HizmetlerimizProfil = () => {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img alt='profil kesim' className='w-100' src="/images/profilBanner.png" ></img>
                    <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                            <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                                Profil Kesim
                            </p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container>
                <Row className='mt-3'>
                    <BreadCrumb />

                    <Col sm={3} className='mt-2 mb-2'>
                    <Card style={{ border: "none"  , borderRadius:"10px", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim1.jpg" />
                        </Card>
                    </Col>

                    
                    <Col sm={3} className='mt-2 mb-2'>
                        <Card style={{ border: "none"  , borderRadius:"10px", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim2.png" />
                        </Card>
                    </Col>



                </Row>
            </Container>
        </>
    )
}

export default HizmetlerimizProfil;