import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom';
export default function Footer() {


  return (



    <div className="footer-clean" style={{ alignItems: "flex-end", Bottom: "0", backgroundColor: "whitesmoke" }}>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-md-3 item ">
              <h3 style={{ color: "#0b7fab" }}>İletişim  </h3>
              <ul>

                <Link to="about">
                  <li className='fw-bold' style={{ color: "black", display: "flex", flexDirection: "column" }}>
                    <div>
                      <i class="fa-solid fa-mobile-screen  p-2 fa-beat" style={{ color: "red" }}></i>
                      <a href="tel:05345184717" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2 '> 0534 518 4717</a>
                    </div>
                    <div>
                      <i class="fa-solid fa-mobile-screen p-2 fa-beat" style={{ color: "orange" }}></i>
                      <a href="tel:05451225828" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2'> 0545 122 5828</a>
                    </div>
                    <div>
                      <i class="fa-solid fa-phone p-2 fa-beat" style={{ color: "blue" }}></i>
                      <a href="tel:05451225828" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2'>0216 455 0028</a>
                    </div>
                      <div>
                      <i class="fa-solid fa-envelope p-2 fa-beat" style={{color:"#38b6ff"}}></i>
                                <a href="mailto:info@cnclazermetalkesim.com"  style={{ color: "black" }} className='iconlar fw-bold '>info@cncmetalkesim.com</a>

                      </div>
                   
                  </li>
                </Link>
              </ul>
            </div>

            <div className="col-sm-4 col-md-3 px-5  item">
              <h3 style={{ color: "#0b7fab" }}>Hızlı  Link</h3>
              <ul>

                <Link to="uretimlerimiz">
                  <li className='fw-bold' style={{ color: "black" }}> Üretimlerimiz</li>
                </Link>

                <Link to="hizmetlerimiz">
                  <li className='fw-bold' style={{ color: "black" }}> Hizmetlerimiz</li>

                </Link>
                <Link to="iletisim">
                  <li className='fw-bold' style={{ color: "black" }}>İletişim</li>
                </Link>

              </ul>
            </div>

            <div className="col-sm-4 col-md-3 item ">
              <h3 style={{ color: "#0b7fab" }}>Adres  </h3>
              <ul>

                <Link to="about">
                  <li className='fw-bold' style={{ color: "black" }}>Yukarı Dudullu, İMES Sanayi Sitesi, c-309 Sk No:17, 34776 Ümraniye/İstanbul</li>
                </Link>
              </ul>
            </div>

            <div className="col-lg-3  col-md-3 item social">
              <img src="/images/logo3.png" className='logoimagesFooter ' alt="cnc lazer kesim metal logo" />
              <p className="copyright fw-bold px-2" style={{ color: "black" }}>CNCLAZERMETALKESİM © 2023</p>
            </div>
          </div>
        </div>
      </footer>
    </div>

  )
}

