import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import "./HizmetlerimizDetay.css";
import { Link } from "react-router-dom";
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
const HizmetlerimizDetay = () => {
  return (
    <>


      <Carousel  className="custom-carousel-slider1"  indicators={false} > 
        <Carousel.Item>
          <img alt='cnc lazer kesim' className='w-100' src="/images/cnclazerkesimDetay.png" ></img>
          <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                Cnc Lazer Kesim
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Container>

          
        <Row className='mt-3'>
        <BreadCrumb />
          <Row>
            <Col sm={3} className='mb-5 mt-4'>
              <Link  to="/hizmetlerimiz/cnclazerkesim/demirdkpkesim" style={{ textDecoration: "none" }}>
              
                <Card style={{ border: "none", height: "15rem", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)" }} className='cardHizmetlerimiz '>
                  <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/dkpsiyah.png" />
                  <Card.Body style={{ marginTop: "-20%", height: "50px", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h3 className='text-center mt-2' style={{ color: "white" }}>Demir-Dkp Kesim</h3>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col sm={3} className='mb-5 mt-4'>
              <Link to="/hizmetlerimiz/cnclazerkesim/paslanmazkesim" style={{ textDecoration: "none" }}>
                <Card style={{ border: "none", height: "15rem", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)" }} className='cardHizmetlerimiz '>
                  <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/paslanmazAnaMenu.png" />
                  <Card.Body style={{ marginTop: "-20%", height: "50px", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h3 className='text-center mt-2' style={{ color: "white" }}>Paslanmaz Kesim</h3>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col sm={3} className='mb-5 mt-4'>
              <Link  to="/hizmetlerimiz/cnclazerkesim/galvanizkesim" style={{ textDecoration: "none" }}>
                <Card style={{ border: "none", height: "15rem", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)" }} className='cardHizmetlerimiz '>
                  <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/galveniz.png" />
                  <Card.Body style={{ marginTop: "-20%", height: "50px", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h3 className='text-center mt-2' style={{ color: "white" }}>Galveniz Kesim</h3>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col sm={3} className='mb-5 mt-4'>
              <Link  to="/hizmetlerimiz/cnclazerkesim/ozelmotifkesim" style={{ textDecoration: "none" }}>
                <Card style={{ border: "none", height: "15rem", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)" }} className='cardHizmetlerimiz '>
                  <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/motifKesimler.jpg" />
                  <Card.Body style={{ marginTop: "-20%", height: "50px", backgroundColor: "rgba(58, 83, 155, 0.6)", borderRadius: "0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h3 className='text-center mt-2' style={{ color: "white" }}>Özel Motif Kesim</h3>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

          </Row>

        </Row>
      </Container>
    </>
  )
}

export default HizmetlerimizDetay;