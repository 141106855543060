import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import "./UrunTanıtımı.css";
import EffectCard from './EffectCard/EffectCard';
const UrunTanıtımı = () => {
  return (
    <div  style={{zIndex:"-1000"}}>
      <Container fluid className='mt-4'>

        <Row >
          <Col className='UrunTanıtımYazi p-3' sm={6}  >
            <h2 className='dkpyazi fw-bold p-3' style={{ color: "rgba(40, 67, 135, 1)", }}>
              DKP'den HRP'ye, Siyah Sactan Paslanmaza ve Galvanizden Her Türlü Sac İşleme Çözümleriyle 
            </h2>
            <h3 className='fw-bold p-3' style={{ color:"rgba(242, 38, 19, 1)"}}>
            Size En İyi Cnc Lazer  Kesimi Sunuyoruz!
            </h3>
            <p className='mt-3 px-3 fw-normal'>
              Kalitemiz ve Hassasiyetimizle Her Kesimde Fark Yaratıyoruz. Projelerinizi İnce Detaylarıyla Şekillendirmek İçin Buradayız.
            </p>
          </Col>
          <Col sm={4} className='col4'><EffectCard />

          </Col>
          <Col className='ensag  p-5' sm={2}  >

          <div className='ensagyazilar'>

          </div>
            <h4 className='fw-bold' style={{ color: "rgba(192, 57, 43, 1)", }}>
              Hassas Kesim
            </h4>

            <div className=' fw-light'>
              <p>CNC ile MM kesimi, hassas, tekrarlanabilir ve yüksek kaliteli sonuçlar elde etmek için kullanılır.</p>
            </div>
        
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UrunTanıtımı;