import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from 'react-bootstrap/Card';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import "./Blog.css";
// import required modules
import { EffectCards } from 'swiper/modules';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
export const Blog = () => {
  return (
    <>

      <Helmet>
        <title>BLOG - cnclazermetalkesim.com</title>
        <meta name="description" content="Cnc lazer kesim , cnc büküm işlemleri , profil kesim işlemleri." />
        <meta name="keywords" content="Cnc lazer kesim metal  nasıl yapılır , cnc büküm  nasıl yapılır , profil kesim nasıl yapılır ." />
      </Helmet>

      <Container></Container>

      <Carousel className="custom-carousel-slider1" indicators={false}>
        <Carousel.Item>
          <img alt='blog cnc ' className='w-100' src="/images/bukumBanner.png" ></img>
          <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                Blog
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container>

        <BreadCrumb></BreadCrumb>

        <h3 className='mt-2 mb-2 px-1 p-3' style={{ color: "rgba(1, 1, 122, 1)" }}>CNC LAZER KESİM İŞLEMİ</h3>


        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.5)", borderRadius: "20px" }} className='mb-3 mt-2'>
          <Row>

            <Col sm={5}>
              <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="blogswiper mt-4 mb-3"
              >

                <SwiperSlide >
                  <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img style={{ border: "none", borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/dkpsiyah.png" />
                  </Card>

                </SwiperSlide>
                <SwiperSlide style={{}}>
                  <Card style={{ boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "90%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img style={{ borderRadius: "0px", width: "100%", height: "100%" }} variant="top" src="/images/paslanmazAnaMenu.png" />
                  </Card>
                </SwiperSlide>

               

              </Swiper>
            </Col>

            <Col sm={6} className="mt-3">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab className='p-2' eventKey="home" title="Cnc Lazer Kesim  ">
                  <div >
                    <Card style={{ border: "none" }}>
                      <Card.Title className='px-2' >Cnc Lazer Kesim İşlemi Nasıl Yapılır ?</Card.Title>
                      <p className='px-2'>CNC lazer kesim, bilgisayar kontrollü lazer kesme makinelerinin kullanıldığı bir otomasyon işlemidir.
                        İlk adım, kesilecek nesnenin tasarımının yapılmasıdır. Bu tasarım daha sonra G-kodlarına dönüştürülerek lazer kesim makinesine yüklenir.
                        Malzeme hazırlığı ve kesim makinesinin ayarları yapılırken, CNC kontrol yazılımı kullanılır. </p>
                      <p className='px-2'>
                        Kesim işlemi sırasında lazer ışını, tasarımın belirlediği yolu takip ederek malzemeyi keser. Sonuçlar kontrol edildikten sonra, kesilen parçaların kalitesi değerlendirilir. CNC lazer kesim, birçok endüstride kullanılan bir yöntemdir ve yüksek hassasiyet ve hız sunar
                      </p>

                    </Card>
                  </div>

                </Tab>
                <Tab eventKey="profile" title="Kalınlıklar">
                  <h5 className='px-3'>Aliminyum</h5>
                  <ul>
                    <li>
                      1-6mm
                    </li>

                  </ul>
                  <h5 className='px-3'>Galvaniz</h5>
                  <ul>
                    <li>
                      1-3mm
                    </li>

                  </ul>
                  <h5 className='px-3'>Siyah HRP-DKP</h5>
                  <ul>
                    <li>
                      1-15mm
                    </li>

                  </ul>
                  <h5 className='px-3'>Paslanmaz</h5>
                  <ul>
                    <li>
                      1-6mm
                    </li>

                  </ul>
                </Tab>

              </Tabs>
            </Col>
          </Row>
        </Card>


        <h3 className='mt-2 mb-2 px-1 p-3' style={{ color: "rgba(1, 1, 122, 1)" }}>CNC BUKUM İŞLEMİ</h3>
        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(90, 34, 139, 0.5)", borderRadius: "20px" }} className='mb-3 mt-2'>
          <Row>
            <Col sm={5}>
              <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="blogswiper mt-4 mb-3"
              >

                <SwiperSlide >
                  <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img style={{ border: "none", borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum4.png" />
                  </Card>

                </SwiperSlide>
                <SwiperSlide style={{}}>
                  <Card style={{ boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img style={{ borderRadius: "0px", width: "100%", height: "100%" }} variant="top" src="/images/bukum3.png" />
                  </Card>
                </SwiperSlide>

               

              </Swiper>
            </Col>

            <Col sm={6} className="mt-3">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab className='p-2' eventKey="home" title="Cnc bukum işlemi   ">
                  <div >
                    <Card style={{ border: "none" }}>
                      <Card.Title className='px-2' >Cnc Büküm  İşlemi Nasıl Yapılır ?</Card.Title>
                      <p className='px-2'>
                        CNC bukum işlemi, malzemelerin hassas ve tekrarlanabilir şekilde işlenmesini sağlayan bir otomasyon yöntemidir. İlk adım, bir bilgisayar programı aracılığıyla oluşturulan bir CNC kodu ile iş parçasının tasarımının ve kesme yollarının belirlenmesidir. CNC makinesi, bu kodu okur ve belirtilen yolları takip ederek kesici ucu (bıçağı) iş parçasına yönlendirir. Bu, iş parçasını kesmek, delmek veya şekillendirmek için kullanılır. CNC bukum işlemi, yüksek hassasiyet ve tekrarlanabilirlik sağlar, böylece karmaşık veya özel parçaların üretimi için idealdir. Ayrıca, işleme süreci sırasında kesme hızı ve diğer parametreler kolayca ayarlanabilir, böylece çeşitli malzemelerde ve kalınlıklarda çalışmak mümkün olur. CNC bukum işlemi, metal işleme, otomotiv üretimi, havacılık ve daha birçok endüstride yaygın olarak kullanılır.
                      </p>

                    </Card>
                  </div>

                </Tab>

              </Tabs>
            </Col>
          </Row>
        </Card>


        <h3 className='mt-2 mb-2 px-1 p-3' style={{ color: "rgba(1, 1, 122, 1)" }}>PROFİL KESİM</h3>
        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(247, 239, 232, 1)", borderRadius: "20px" }} className='mb-3 mt-2'>
          <Row>

            <Col sm={5}>


              <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="blogswiper mt-4 mb-3"
              >

                <SwiperSlide >
                  <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img alt='profil kesim işlemi' style={{ border: "none", borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim2.png" />
                  </Card>

                </SwiperSlide>
                <SwiperSlide style={{}}>
                  <Card style={{ boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                    <Card.Img alt='profil kesim işlemi' style={{ borderRadius: "0px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim.png" />
                  </Card>
                </SwiperSlide>



              </Swiper>
            </Col>

            <Col sm={6} className="mt-3">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab className='p-2' eventKey="home" title="Profil Kesim  ">
                  <div >
                    <Card.Title className='px-2' > Profil Kesim  İşlemi Nasıl Yapılır ?</Card.Title>
                    <Card style={{ border: "none" }}>
                      <p className='px-2'>
                        Profil kesim işlemi, birçok endüstride kullanılan önemli bir metal işleme yöntemidir. Bu işlem, çeşitli metal profillerin özel kesim ve şekillendirmelerini yapmak için kullanılır. Profil kesim işlemi, inşaat sektöründen otomotiv endüstrisine kadar birçok uygulamada yaygın olarak kullanılır. Bu işlem, metallerin kesilmesi ve şekillendirilmesi için özel olarak tasarlanmış CNC (Bilgisayarlı Sayısal Kontrol) makineleri ile gerçekleştirilir. Bu makineler, önceden programlanmış kodları kullanarak kesme işlemini otomatikleştirir ve yüksek hassasiyetli sonuçlar elde edilmesini sağlar. Profil kesim işlemi, tasarım esnekliği ve özel projeler için ideal bir seçenek sunar ve iş parçalarının özel gereksinimlere uygun olarak üretilmesini sağlar. Endüstriyel üretimde verimliliği artırırken, kesme işleminin detaylarına odaklanmak da ürün kalitesini artırır ve malzeme israfını azaltır. Profil kesim işlemi, yüksek kaliteli, özelleştirilmiş metal parçaların üretimini mümkün kılar ve bu nedenle endüstriyel ve inşaat projeleri için önemlidir.

                      </p>

                    </Card>
                  </div>

                </Tab>

              </Tabs>
            </Col>
          </Row>
        </Card>

      </Container>
    </>
  )
}
