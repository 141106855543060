
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import "./Header.css";
const Header = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [show, setShow] = useState(false);


    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    window.onscroll = function () { scrollFunction() };


    function scrollFunction() {
        const navbar = document.getElementById("navbar");
        const navbarButtons = document.querySelectorAll(".navbarbtn");

        if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
            navbar.style.backgroundColor = "white";
            navbarButtons.forEach(btn => (btn.style.color = "black"));

        } else {
            navbar.style.backgroundColor = "transparent";
            navbarButtons.forEach(btn => (btn.style.color = "white"));

        }
    }


    const toggleNavbar = () => {
        if (show) {
            handleClose(); // Navbar açıksa, kapat
        } else {
            handleShow(); // Navbar kapalıysa, aç
        }
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            {['lg'].map((expand) => (
                <Navbar id='navbar' key={expand} expand={expand} >
                    <Link style={{ textDecoration: "none" }} to="/">
                        <Navbar.Brand  ><img className='logo' src="/images/logo3.png" alt="" /></Navbar.Brand>
                    </Link>
                    <div className='hamburgerToggle ' >
                        <Navbar.Toggle  className='navbarToggle mb-3' onClick={toggleNavbar} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    </div>
                    <Navbar.Offcanvas

                        style={{ width: '100% ' , zIndex:"2000"}}
                        show={show}
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header className='offcanvasHeaderMobil' >
                        </Offcanvas.Header>
                        <Offcanvas.Body className='offcanvasHeaderMobilBody  ' >
                            <Nav className="navbarMobil justify-content-end flex-grow-1  mt-2   ">
                                <Link className={`navItem ${activeItem === 'home' ? 'active' : ''}`} onClick={() => { handleItemClick('home'); handleClose() }} to="/" ><h5 style={{ fontSize: "15px" }}>Anasayfa</h5> </Link>
                                <Link className={`navItem ${activeItem === 'kurumsal' ? 'active' : ''}`} onClick={() => { handleItemClick('kurumsal'); handleClose() }} to="kurumsal" > <h5 style={{ fontSize: "15px" }}>Kurumsal</h5> </Link>

                                <Link className={`navItem ${activeItem === 'uretimlerimiz' ? 'active' : ''}`} onClick={() => { handleItemClick('uretimlerimiz'); handleClose() }} to="uretimlerimiz" ><h5 style={{ fontSize: "15px" }}>Üretimlerimiz</h5></Link>


                                <Link className={`navItemUrunler ${activeItem === 'urunler' ? 'active' : ''}`} onClick={() => { handleItemClick('urunler'); handleClose() }} to="hizmetlerimiz"><h5 style={{ fontSize: "15px"  }}>Hizmetlerimiz</h5></Link>
                                <div className="dropdown-content" >
                                    <Link className={`navItemUrunler ${activeItem === 'cnclazerkesim' ? 'active' : ''}`} onClick={() => { handleItemClick('urunler'); handleClose() }} to="/hizmetlerimiz/cnclazerkesim"  >CNC LAZER KESİM</Link>
                                    <Link className={`navItemUrunler ${activeItem === 'cncbukum' ? 'active' : ''}`} onClick={() => { handleItemClick('urunler'); handleClose() }} to="/hizmetlerimiz/cncbukum">CNC BÜKÜM</Link>
                                    <Link className={`navItemUrunler ${activeItem === 'profilkesim' ? 'active' : ''}`} onClick={() => { handleItemClick('urunler'); handleClose() }} to="/hizmetlerimiz/profilkesim" >PROFİL KESİM</Link>

                                </div>
                                <Link className={`navItem ${activeItem === 'blog' ? 'active' : ''}`} onClick={() => { handleItemClick('blog'); handleClose() }} to="blog" ><h5 style={{ fontSize: "15px" }}>Blog</h5></Link>
                                <Link className={`navItem ${activeItem === 'iletisim' ? 'active' : ''}`} onClick={() => { handleItemClick('iletisim'); handleClose() }} to="iletisim" ><h5 style={{ fontSize: "15px" }}>İletişim</h5></Link>
                            </Nav>


                            <div className='d-flex p-3 gap-3 mt-1' style={{ borderLeft: "1px solid", borderColor: "pink" }}>
                                <div>
                                    <a  href="https://api.whatsapp.com/send?phone=+905345184717&text=Merhaba Cnc Lazer Kesim Fiyatları Hakkında Bilgi Alabilirmiyim!">
                                        <i className="fab fa-whatsapp fa-lg" style={{ color: "#04aa6d" }}></i>
                                    </a>

                                </div>

                                <div>
                                    <a target='blank' href="https://www.instagram.com/kullanici-adi">
                                        <i className="fab fa-instagram fa-lg" style={{ color: "#f40196" }}></i>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.facebook.com/kullanici-adi">
                                        <i className="fab fa-facebook fa-lg" style={{ color: "#1877f2" }}></i>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.youtube.com/kanal-adi">
                                        <i className="fab fa-youtube fa-lg" style={{ color: "#ff0000" }}></i>
                                    </a>

                                </div>
                            </div>



                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
            ))}


        </>

    )
}

export default Header;