import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import "./Iletisim.css";
import { Helmet } from 'react-helmet';

export const Iletisim = () => {
    return (
        <>


            <Helmet>
                <title>İLETİŞİM - cnclazermetalkesim.com</title>
            </Helmet>
            <Carousel className="custom-carousel-slider1" indicators={false}>
                <Carousel.Item>
                    <img alt='cnc lazer kesim' className='w-100' src="/images/iletisim.png" ></img>
                    <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                            <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                                Bize Ulaşın
                            </p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container className='p-5'>

                <Row>
                    <Col className='w-full ' sm={5} style={{ marginTop: "7%" }}>
                        <ListGroup>
                            <ListGroup.Item className='mb-2' style={{ borderRadius: "10px" }}>
                                <i class="fa-solid fa-mobile-screen  p-2 fa-beat" style={{ color: "red" }}></i>
                                <a href="tel:05345184717" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2 '> 0534 518 4717</a>
                            </ListGroup.Item>
                            <ListGroup.Item className='mb-2' style={{ borderRadius: "10px" }}>
                                <i class="fa-solid fa-mobile-screen p-2 fa-beat" style={{ color: "orange" }}></i>
                                <a href="tel:05451225828" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2'> 0545 122 5828</a>
                            </ListGroup.Item>
                            <ListGroup.Item className='mb-2' style={{ borderRadius: "10px" }}>
                                <i class="fa-solid fa-phone p-2 fa-beat" style={{ color: "blue" }}></i>
                                <a href="tel:02164550028" style={{ color: "#007fb1" }} className='iconlar fw-bold p-2'>0216 455 0028</a>
                            </ListGroup.Item>


                            <ListGroup.Item className='mb-2' style={{ borderRadius: "10px" }}>
                                <i class="fa-solid fa-envelope p-2 fa-beat" style={{color:"gray"}}></i>
                                <a href="mailto:info@cnclazermetalkesim.com" className='iconlar fw-bold '>info@cncmetalkesim.com</a>


                            </ListGroup.Item>

                            <ListGroup.Item className='mb-2' style={{ borderRadius: "10px" }}>
                                <address className='fw-bold'>
                                    <i class="fa-solid fa-location-dot p-2 fa-beat" style={{ color: "pink" }}></i>
                                    Yukarı Dudullu, İMES Sanayi Sitesi, c-309 Sk No:17, 34776 Ümraniye/İstanbul</address>

                            </ListGroup.Item>

                        </ListGroup>


                    </Col>

                    <Col sm={7}>

                        <div className="harita-container mt-2">


                            <iframe title='Cnc Lazer Kesim Metal' className='harita' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d895.2435278661072!2d29.15977915066566!3d40.99837616322211!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cacfcea761c0ed%3A0xe23c9656c879d2a6!2sCnc%20Lazer%20Metal%20Kesim!5e0!3m2!1str!2str!4v1698134450535!5m2!1str!2str"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>

                    </Col>
                </Row>

            </Container>
        </>
    )
}
