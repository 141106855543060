import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Kurumsal.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import { Helmet } from 'react-helmet';
const Kurumsal = () => {
    return (
        <div>

            <Helmet>
                <title>KURUMSAL - cnclazermetalkesim.com</title>
            </Helmet>

            <Carousel className="custom-carousel-slider1" indicators={false}>
                <Carousel.Item>
                    <img alt="cnc lazer kesim " className=' w-100' src="/images/gif.gif" ></img>
                    <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                            <p className='urteimlerimizYazi mt-5  fw-bold w3-animate-top'>
                                Kurumsal
                            </p>
                        </div>
                    </Carousel.Caption>

                </Carousel.Item>
            </Carousel>


            <div className='ilkRow  '>

                <Container>

                    <Row className=''>
                        <BreadCrumb />
                        <Col sm={6} xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <img alt='cnc lazer kesim'  className='kurumsalfoto mt-3 p-2' src='https://i.hizliresim.com/pk54txx.png'></img>
                        </Col>
                        <Col sm={6} className='mt-4' xs={12}>
                            <div >
                                <h2 className='fw-bold'>
                                    CNC LAZER METAL KESİM
                                </h2>
                                <p className='fw-normal px-1'>
                                    CNC lazer metal kesim alanında vizyonumuz, müşterilerimize endüstri lideri kalitede kesim hizmeti sunarak, işlerini daha kesin, hızlı ve verimli hale getirmelerine yardımcı olmaktır. CNC teknolojisi ve lazer kesim hassasiyetiyle öne çıkarak, müşteri memnuniyetini ve sonuçların mükemmelliğini garanti etmeyi amaçlıyoruz.
                                </p>
                                <p className='fw-normal px-1'>
                                    Misyonumuz, sadece ürünlerimizi geliştirmekle sınırlı değil, aynı zamanda sürdürülebilir üretim uygulamalarıyla topluma ve çevreye olan sorumluluğumuzu da yansıtıyor. Müşterilerimize özel çözümler sunarak onların gereksinimlerini aşmaya çalışıyoruz ve aynı zamanda teknolojik yenilikleri takip ederek sektörde lider olma yolunda ilerliyoruz.
                                </p>
                                <p className='fw-normal px-1'>
                                    CNC lazer metal kesimde güvenilir bir iş ortağı olmak için çaba gösteriyor, müşterilerimizin işlerini büyütmelerine ve rekabet avantajı elde etmelerine destek oluyoruz.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mobilvizyon1'>
                        <Col sm={6} xs={12} className='mt-5  ' >
                            <div className=' mt-4' >

                                <h2 className='fw-bold'>
                                    Vizyonumuz
                                </h2>
                                <p className='px-1 fw-bold'>Müşteri Memnuniyeti </p>
                                <p className='px-1 fw-normal'>Müşterilerimizin beklentilerini aşmak için çalışıyoruz. Onların ihtiyaçlarını anlamak ve özel gereksinimlerini karşılamak için esneklik ve kişiselleştirilmiş çözümler sunuyoruz. Müşteri memnuniyetini sağlayarak uzun vadeli iş ilişkileri kurmayı amaçlıyoruz.</p>
                                <p className='px-1 fw-bold'>İnovasyon Liderliği </p>
                                <p className='px-1 fw-normal'>Her zaman en yeni teknolojileri ve süreçleri takip ederek, CNC lazer metal kesiminde inovasyonun lideri olmayı hedefliyoruz. Sürekli olarak yeni ve geliştirilmiş çözümler sunarak, müşterilerimize sınırları aşma fırsatları sağlayacağız.</p>
                            </div>
                        </Col>
                        <Col sm={6} style={{ display: "flex", justifyContent: "center" }} className='mt-5'>
                            <img alt="cnc lazer kesim" className='kurumsalfoto mt-3 p-2'  src='https://i.hizliresim.com/8nff86j.png'></img>
                        </Col>
                    </Row>
                    <Row className='mobilVizyon'>
                        <Col sm={6} style={{ display: "flex", justifyContent: "center" }} className='mt-5'>
                            <img alt="cnc lazer kesim " className='kurumsalfoto mt-3 p-2'  src='https://i.hizliresim.com/8nff86j.png'></img>
                        </Col>
                        <Col sm={6} xs={12} className='mt-2' >
                            <div className='' >

                                <h2 className='fw-bold'>
                                    VİZYONUMUZ
                                </h2>
                                <p className='px-1 fw-bold'>Müşteri Memnuniyeti </p>
                                <p className='px-1 fw-normal'>Müşterilerimizin beklentilerini aşmak için çalışıyoruz. Onların ihtiyaçlarını anlamak ve özel gereksinimlerini karşılamak için esneklik ve kişiselleştirilmiş çözümler sunuyoruz. Müşteri memnuniyetini sağlayarak uzun vadeli iş ilişkileri kurmayı amaçlıyoruz.</p>
                                <p className='px-1 fw-bold'>İnovasyon Liderliği </p>
                                <p className='px-1 fw-normal'>Her zaman en yeni teknolojileri ve süreçleri takip ederek, CNC lazer metal kesiminde inovasyonun lideri olmayı hedefliyoruz. Sürekli olarak yeni ve geliştirilmiş çözümler sunarak, müşterilerimize sınırları aşma fırsatları sağlayacağız.</p>
                            </div>
                        </Col>

                    </Row>


                    <Row className='mt-3'>
                        <Col sm={6} style={{ display: "flex", justifyContent: "center" }}>
                            <img className='kurumsalfoto mt-3 p-2' alt='' src='https://i.hizliresim.com/ednchcv.png'></img>
                        </Col>
                        <Col sm={6} className=''>
                            <div className=''>

                                <h2 className='fw-bold'>
                                    İş Ortaklarımız Ve Alanlarımız
                                </h2>

                                <p>
                                    <strong>Elektrik Panoları ve Endüstriyel İşbirlikleri</strong>
                                </p>

                                <p>
                                    Elektrik panoları ve endüstriyel ekipmanlar, güç dağıtımının ve üretim süreçlerinin temel bileşenleridir. CNC lazer metal kesim hizmetlerimiz, özellikle bu sektörlerde, iş ortaklarımızın gereksinimlerini karşılamak için mükemmel bir çözüm sunar. İnovasyon ve sürdürülebilirlik ilkelerimiz, önde gelen firmalarla olan işbirliklerimizi daha da güçlendirir.
                                </p>

                                <p>
                                    <strong>ABB, Siemens ve Diğer Büyük Markalarla İşbirliği</strong>
                                </p>

                                <p>
                                    ABB, Siemens ve benzeri büyük markalarla yaptığımız işbirlikleri, sektördeki güvenilirliğimizi ve üstün kalitemizi göstermektedir. Bu işbirlikleri, teknolojik yenilikleri ve endüstri standartlarını yakından takip etmemizi sağlar. Bu sayede, müşterilerimize her zaman en iyi ürünleri sunarız.
                                </p>

                                <p>
                                    CNC lazer metal kesimdeki bu işbirlikleri ve inovasyon odaklı yaklaşımımız, endüstrinin önde gelen ismi olma hedefimize ulaşmak için bizi ileriye taşıyor.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>


            </div>

        </div>
    )
}

export default Kurumsal;