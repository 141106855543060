import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./EffectCard.css"
const EffectCard = () => {
    return (
        <div  className='kartimagebakalım w-100 mt-4' style={{ display: "flex", justifyContent: "center" , zIndex:"-1000" }}>


            <Carousel className='effectCard' >
                <Carousel.Item   >


                    <img

                        className="effectCardfoto d-block w-100 "
                        src="/images/urunlerimizAliminyum.png"
                        alt="First slide"
                    />

                </Carousel.Item>
                <Carousel.Item   >

                    <div >


                    </div>
                    <img

                        className="effectCardfoto d-block w-100 "
                        src="/images/urunlerimizTanitim.png"
                        alt="First slide"
                    />

                </Carousel.Item>



                <Carousel.Item   >

                    <div >


                    </div>
                    <img

                        className="effectCardfoto d-block w-100 "
                        src="/images/urunlerimizsiyahdkp.png"
                        alt="First slide"
                    />

                </Carousel.Item>
            

              
            </Carousel>

        </div>
    )
}

export default EffectCard