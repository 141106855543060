
import './App.css';
import {Routes,Route,HashRouter } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import Kurumsal from './pages/Kurumsal/Kurumsal';
import Hizmetlerimiz from './pages/Hizmetlerimiz/Hizmetlerimiz';
import HizmetlerimizDetayCnc from './pages/HizmetlerimizDetay/HizmetlerimizCnc/HizmetlerimizDetayCnc';
import HizmetlerimizBukum from './pages/HizmetlerimizDetay/HizmetlerimizBukum/HizmetlerimizBukum';
import HizmetlerimizProfil from './pages/HizmetlerimizDetay/HizmetlerimizProfil/HizmetlerimizProfil';
import { Blog } from './pages/Blog/Blog';
import { Iletisim } from './pages/Iletisim/Iletisim';
import DemirDkpKesim from './pages/HizmetlerimizDetay/HizmetlerimizCnc/DemirDkpKesim/DemirDkpKesim';
import Uretimlerimiz from "./pages/Uretimlerimiz/Uretimlerimiz";
import PaslanmazKesim from './pages/HizmetlerimizDetay/HizmetlerimizCnc/PaslanmazKesim/PaslanmazKesim';
import GalvanizKesim from './pages/HizmetlerimizDetay/HizmetlerimizCnc/GalvanizKesim/GalvanizKesim';
import OzelMotifKesim from './pages/HizmetlerimizDetay/HizmetlerimizCnc/OzelMotifKesim/OzelMotifKesim';

function App() {


   return (
      <div className='anasayfa'>
         <HashRouter>
            <Routes>
               <Route path="/" element={<Layout />} >
                  <Route index element={<Home />} />
                  <Route path="kurumsal" element={<Kurumsal />}>
                  </Route>
                  <Route path="hizmetlerimiz" element={<Hizmetlerimiz />}>      </Route>  
                  <Route path="/hizmetlerimiz/cnclazerkesim"  element={<HizmetlerimizDetayCnc  />}></Route>
                  <Route path='uretimlerimiz' element={<Uretimlerimiz />}></Route>
                  <Route path="/hizmetlerimiz/cnclazerkesim/demirdkpkesim" element={<DemirDkpKesim />}></Route>
                     <Route path="/hizmetlerimiz/cnclazerkesim/paslanmazkesim"  element={<PaslanmazKesim />}></Route>
                     <Route path="/hizmetlerimiz/cnclazerkesim/galvanizkesim" element={<GalvanizKesim />}></Route>
                     <Route path="/hizmetlerimiz/cnclazerkesim/ozelmotifkesim"  element={<OzelMotifKesim />}></Route>
         
                     <Route   path="/hizmetlerimiz/cncbukum"  element={<HizmetlerimizBukum />}></Route>
                     <Route   path="/hizmetlerimiz/profilkesim"  element={<HizmetlerimizProfil />}></Route>
                  <Route path='blog' element={<Blog />}></Route>
                  <Route path='iletisim' element={<Iletisim />}></Route>

               </Route>
            </Routes>

         </HashRouter>
      </div>
   );
}

export default App;
