import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "../AnasayfaHizmetler.css";
import {  Pagination } from "swiper/modules";
import Card from 'react-bootstrap/Card';
const CncBukumFotolari = () => {
    return (
        <div className='urunlerdivLazerKesim'   >


            <h3 className=' container  px-5  mt-3 ' style={{color: "rgba(1, 1, 122, 1)"  ,whiteSpace:"nowrap" }} >
                CNC BÜKÜMLERİMİZ
            </h3>

            <Swiper

                slidesPerView={1}
                spaceBetween={2}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    "@0.00": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },

                    "@0.60": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },
                    "@0.75": {
                        slidesPerView: 2.3,
                        spaceBetween: 10,
                    },
                    "@1.00": {
                        slidesPerView: 3.2,
                        spaceBetween: 30,
                    },
                    "@1.50": {
                        slidesPerView: 3.8,
                        spaceBetween: 20,
                    },

                    "@2": {
                        slidesPerView: 4.5,
                        spaceBetween: 10,
                    },


                    "@2.2": {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },


                }}
                modules={[Pagination]}
                className="mySwiper  "
            >


          <SwiperSlide className="swiperurunler mt-5" >

           
                        <Card className="cardUrunlerimiz">
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum3.png" />


                        </Card>
                  
                </SwiperSlide>


            <SwiperSlide className="swiperurunler mt-5" >
                
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum4.png" />

                        </Card>
                   
                </SwiperSlide>


            <SwiperSlide className="swiperurunler mt-5" >
                 
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum5.png" />

                        </Card>
                   
                </SwiperSlide>
            <SwiperSlide className="swiperurunler mt-5" >
                   
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum6.png" />

                        </Card>
              
                </SwiperSlide>
            <SwiperSlide className="swiperurunler mt-5" >
                  
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum7.png" />


                        </Card>
                </SwiperSlide>


            <SwiperSlide className="swiperurunler mt-5" >
                   
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum8.png" />

                        </Card>
                
                </SwiperSlide>
            <SwiperSlide className="swiperurunler mt-5" >
                  
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum9.png" />

                        </Card>
                 
                </SwiperSlide>
          <SwiperSlide className="swiperurunler mt-5" >

                  
                        <Card className="cardUrunlerimiz">
                            <Card.Img className='swiperImage' variant="top" src="/images/bukum1.png" />


                        </Card>
                    
                </SwiperSlide>
            </Swiper>

        </div>
    )
}

export default CncBukumFotolari;