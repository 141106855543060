import React from 'react'
import "./offerShort.css";
import Card from 'react-bootstrap/Card';

const OfferShort = () => {
    return (
        <>
            <Card className='offShort' >
                <Card.Body >
                <a target='blank' href="https://api.whatsapp.com/send?phone=+905345184717&text=Merhaba Cnc Lazer Kesim Fiyatları Hakkında Bilgi Alabilirmiyim!" style={{textDecoration:"none"}}>
                    <div className='shortTeklif' >
                            <i className="fab fa-whatsapp fa-2x" style={{ color: "#04aa6d" }}></i>
                        <span style={{ fontSize: "20px" , color:"black"}} className='px-2'>Teklif </span>
                        <span style={{ fontSize: "20px" ,   color:"black"}}>Alın</span>
                    </div>
                    </a>
                </Card.Body>
            </Card>
        </>
    )
}

export default OfferShort;