import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "./AnasayfaHizmetler.css";
import CncLazerKesimFotolarıi from './CncLazerKesimFotolari/CncLazerKesimFotolarıi';
import CncBukumFotolari from './CncBukumFotolari/CncBukumFotolari';
import ProfilKesimFotolari from './ProfilKesimFotolari/ProfilKesimFotolari';
const AnasayfaHizmetler = () => {
  return (
    <div className='hizmetlerimizRowBilgiLer'>




      <h3 className='AmacText container  px-5  ' style={{ color: "rgba(1, 1, 122, 1)" }} >
        HİZMETLERİMİZ
      </h3>
      <Container>
        <Row className='anasayfaHizmetlerRow p-4' style={{ display: "flex", justifyContent: "center" }}>
          <Col className='anasayfaHizmetlerCncLazerKesimRow mt-4' sm={5}>
            <img alt='cnc lazer kesim ' className='solfoto ' style={{ width: "70%", height: "auto", borderRadius: "50px" }} src='/images/cnclazerkesim.png'></img>
          </Col>


          <Col sm={7} style={{ marginTop: "6%" }}>
            <Card className='sundugumuzHizmetlerYazi ' style={{ border: "none", borderRadius: "10px" }}>
              <h4 className='p-3' style={{ color: "rgba(192, 57, 43, 1)", }}>CNC LAZER KESİM NEDİR ?</h4>
              <div className='px-3'>
                <p className=''>
                  Lazer CNC kesim, CNC sistemleri ve yüksek enerjili lazerle çalışarak otomatik ve hassas kesimler yapar.
                </p>

                <p className=''>
                  Son derece hassas kesimler ve ince detaylar için idealdir, tekrarlanabilirlik açısından yüksek hassasiyet sunar.
                </p>
                <p className=''>
                  Hızlı kesim süreleri ile üretim süreçlerini hızlandırır ve maliyetleri azaltır.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
        <CncLazerKesimFotolarıi />
      </Container>

      <Container>
        <Row className='ProfilKesimMobil  p-4' style={{ marginTop: "-4%" }}>
          <Col className='anasayfaHizmetlerCncLazerKesimRow ' sm={5}>
            <img alt='profil kesim' className='sagfoto ' style={{ width: "70%", height: "auto", borderRadius: "50px" }} src='images/cncbukum.png'></img>
          </Col>
          <Col sm={7}>
            <Card className='sundugumuzHizmetlerYazi mt-5' style={{ border: "none", borderRadius: "10px" }}>
              <h4 className='p-3' style={{ color: "rgba(192, 57, 43, 1)", }}>CNC BÜKÜM NEDİR ?</h4>
              <div className='px-3'>
                <p className=''>
                  Lazer CNC kesim, CNC sistemleri ve yüksek enerjili lazerle çalışarak otomatik ve hassas kesimler yapar.
                </p>

                <p className=''>
                  Son derece hassas kesimler ve ince detaylar için idealdir, tekrarlanabilirlik açısından yüksek hassasiyet sunar.
                </p>
                <p className=''>
                  Hızlı kesim süreleri ile üretim süreçlerini hızlandırır ve maliyetleri azaltır.
                </p>
              </div>
            </Card>
          </Col>

        </Row>

      </Container>
      <Container >
        <Row className='ProfilKesimMobil1 p-4' style={{ marginTop: "0%" }}>
          <Col sm={7}>
            <Card className=' sundugumuzHizmetlerYazi mt-4 ' style={{ border: "none", borderRadius: "10px" }}>
              <h4 className='p-3' style={{ color: "rgba(192, 57, 43, 1)", }}>CNC BÜKÜM NEDİR ?  </h4>
              <div className='px-3'>
                <p >
                  CNC bukum, metal işleme endüstrisindeki birçok sektör için vazgeçilmez bir teknoloji haline gelmiştir.CNC bukum makineleri, endüstriyel üretimde yüksek hassasiyet ve hızlı üretim sağladığı için tercih edilir.
                </p>

                <p className=''>
                  Bu bilgisayar kontrollü sistemler, metali kesme, bükme, ve delme işlemlerinde son derece hassas ve verimli bir şekilde işlemek için kullanılır.
                </p>
                <p className=''>
                  CNC bukum makineleri, endüstriyel üretimde yüksek hassasiyet ve hızlı üretim sağladığı için tercih edilir.
                </p>
              </div>
            </Card>
          </Col>
          <Col sm={5}>
            <img alt='profil kesim nedir' className='sagfoto ' style={{ width: "70%", height: "auto", borderRadius: "50px" }} src='images/cncbukum.png'></img>
          </Col>
        </Row>
        <CncBukumFotolari />
      </Container>


      <Container>
        <Row className='p-4' style={{ marginTop: "-2%" }}>
          <Col sm={5} className='anasayfaHizmetlerCncLazerKesimRow' >
            <img alt='profil kesim nedir' className='solfoto ' style={{ width: "70%", height: "auto", borderRadius: "50px" }} src='/images/profilkesim1.png'></img>
          </Col>
          <Col sm={7}>
            <Card className='sundugumuzHizmetlerYazi mt-5' style={{ border: "none", borderRadius: "10px" }}>
              <h4 className='p-3' style={{ color: "rgba(192, 57, 43, 1)", }}>PROFİL KESİM NEDİR ? </h4>
              <div className='px-3'>
                <p className=''>
                  Profil kesim, metal endüstrisinde inovasyon ve esneklik açısından önemli bir rol oynar.
                  Profil kesim işlemi, özelleştirilebilirliği artırır ve metal parçaların kesin ölçülere ve kalite standartlarına uygun olarak üretilmesini sağlar.
                </p>


                <p className=''>
                  Profil kesim teknolojisi, özellikle inşaat, otomotiv, ve metal imalat endüstrilerindeki projeler için vazgeçilmez bir bileşen haline gelmiştir.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
        <ProfilKesimFotolari />
      </Container>
    </div>
  )
}

export default AnasayfaHizmetler; 