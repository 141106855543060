import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb';


const GalvanizKesim = () => {
  return (

    <>


      <Carousel className="custom-carousel-slider1" indicators={false} >
        <Carousel.Item>
          <img alt='galvaniz kesim' className='w-100' src="/images/cnclazerkesimDetay.png" ></img>
          <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <p className='mt-5 yazi-altsatira fw-bold w3-animate-top'>
                Galvaniz Kesim
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container>
        <Row className='mt-3'>
          <BreadCrumb />
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img alt='galveniz kesim' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glv47.png" />
            </Card>
          </Col>

          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img alt='galveniz kesim' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glv55.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img alt='galveniz kesim' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glv63.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img alt='galveniz kesim' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glv83.png" />
            </Card>
          </Col>

          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glv87.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>
              <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glvnz.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glvnz14.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glvnzz.png" />
            </Card>
          </Col>
          <Col sm={3} className='mt-2 mb-2'>
            <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

              <Card.Img style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/glvnzz1.png" />
            </Card>
          </Col>
        </Row>
      </Container>
    </>


  )
}

export default GalvanizKesim;