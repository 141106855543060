import React from 'react'
import Slider from '../../components/slider/Slider'
import AnasayfaKurumsal from '../AnasayfaKurumsal/AnasayfaKurumsal'
import UrunTanıtımı from '../UrunTanıtımı/UrunTanıtımı'
import AnasayfaUrunler from '../AnasayfaUrunler/AnasayfaUrunlar'
import AnasayfaHizmetler from '../AnasayfaHizmetler/AnasayfaHizmetler'
import { IsOrtaklarımız } from '../IsOrtaklarımız/IsOrtaklarımız'
import { Helmet } from 'react-helmet'
const Home = () => {
  return (
    <div>

      <Helmet>
        <title>CNC Lazer Metal Kesim </title>
      </Helmet>
      <Slider />
      <AnasayfaKurumsal />
      <UrunTanıtımı />
      <AnasayfaUrunler />
      <AnasayfaHizmetler />
      <IsOrtaklarımız />
    </div>
  )
}

export default Home;