import React from 'react'
import "./AnasayfaKurumsal.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const AnasayfaKurumsal = () => {
    return (
        <div className='anasayfaKurumsal ' style={{zIndex:"-1000"}}>
            <h3 className='AmacText container  px-5  mt-3' style={{ color: "rgba(1, 1, 122, 1)" }} >
                Siz Tasarlayın Biz Yapalım...
            </h3>
            <Container className='anasayfakurumsalMargin p-4' >

                <Row className='px-1'   style={{  display:"flex" , justifyContent:"center" , }}>

                <Col className='col' sm={3} xs={6} lg={3} md={6} xl={6}>
                        <div className='d-flex'>
                            <div >
                                <div>
                                    <img className='ucboyut  mt-2'  alt='Cnc Tasarlayın' variant="top" src="https://i.hizliresim.com/mjl3ne3.png" />
                                </div>
                                <div className='mt-3 px-2 text-center'>
                                    <div className='d-flex px-2 '>
                                        <div>
                                            <p className='sayiYaziTasarlayin p-1' >1-</p>
                                        </div>
                                        <div>
                                            <p className='yaziTasarlayinYazi mt-1' >Tasarlayın  </p>
                                        </div>
                                    </div>
                                    <p className='yaziTasarlayin fw-normal ' >Ürünlerinizi istediğiniz gibi tasarlayın.</p>
                                </div>

                            </div>
                            <div className='cizgi'>
                                <hr style={{ width: "128px", marginTop: "70%" , color:"green" }} />

                            </div>
                        </div>
                    </Col>

                    <Col className='col ' sm={3} xs={6} lg={3} md={3} xl={3}>
                        <div className='d-flex'>
                            <div>
                                <div>
                                    <img className='ucboyut1 mt-2' alt="Cnc Gonderim"  variant="top" src="https://i.hizliresim.com/9c1gvf4.png" />
                                </div>
                                <div className='mt-3 px-2 text-center'>
                                    <div className='d-flex  px-2'>
                                        <div>
                                            <p className='sayiYaziGonderin p-1'>2-</p>
                                        </div>
                                        <div>
                                            <p className='yaziTasarlayinYazi mt-1' >Gönderin  </p>
                                        </div>
                                    </div>
                                    <p className='yaziTasarlayin fw-normal ' >Tasarladığınız ürünleri bize iletin.</p>
                                </div>

                            </div>
                            <div className='cizgi'>
                            <hr style={{ width: "128px", marginTop: "70%" , color:"pink" }} />


                            </div>
                        </div>
                    </Col>

                    <Col className='col ' sm={3} xs={6} lg={3} md={3} xl={3}>
                        <div className='d-flex'>
                            <div >

                                <div>
                                    <img alt='üretelim' className='ucboyut2 mt-2'  variant="top" src="https://i.hizliresim.com/15ir5f3.png" />
                                </div>
                                <div className='mt-3 px-2 text-center'>
                                    <div className='d-flex px-2'>
                                        <div>
                                            <p className='sayiYaziUretelim p-1' >3-</p>
                                        </div>
                                        <div>
                                            <p className='yaziTasarlayinYazi mt-1'>Üretelim</p>
                                        </div>
                                    </div>
                                    <p className='yaziTasarlayin  fw-normal  '>Ürünlerinizi en kaliteli şekilde üretelim.</p>
                                </div>
                            </div>
                            <div className='cizgi'>
                        <hr style={{ width: "128px", marginTop: "70%" , color:"orange" }} />


                            </div>
                        </div>
                    </Col>
                    <Col className='col ' sm={3} xs={6} lg={3} md={3} xl={3}>
                        <div >

                            <div>
                                <img alt='gönderelim' className='ucboyut3 mt-2'  variant="top" src="https://i.hizliresim.com/54sgsq0.png" />
                            </div>
                            <div className='mt-3  text-center'>
                                    <div className='d-flex  '>
                                        <div>
                                            <p className='sayiYaziGonderelim p-1' >4-</p>
                                        </div>
                                        <div>
                                            <p className='yaziTasarlayinYazi mt-1'>Gönderelim  </p>
                                        </div>
                                    </div>
                                    <p className='yaziTasarlayin1 fw-normal ' >3 Günde ürünlerinizi teslim edelim.</p>
                                </div>
                        </div>
                      
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AnasayfaKurumsal;