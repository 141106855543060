import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./AnasayfaUrunler.css"
import {  Pagination } from "swiper/modules";
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
const AnasayfaUrunler = () => {
    return (
        <div className='urunlerdiv  mt-5 '   >


            <h3 className='AmacText container  px-5' style={{ color: "rgba(1, 1, 122, 1)" }} >
                ÜRETİMLERİMİZ
            </h3>

            <Swiper

                slidesPerView={1}
                spaceBetween={2}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    "@0.00": {
                        slidesPerView: 2.4,
                        spaceBetween: 10,
                    },

                    "@0.60": {
                        slidesPerView: 2.4,
                        spaceBetween: 10,
                    },
                    "@0.75": {
                        slidesPerView: 2.4,
                        spaceBetween: 10,
                    },
                    "@1.00": {
                        slidesPerView: 3.2,
                        spaceBetween: 30,
                    },
                    "@1.50": {
                        slidesPerView: 5.5,
                        spaceBetween: 10,
                    },
                   
                    "@2": {
                        slidesPerView: 5.5,
                        spaceBetween: 10,
                    },
                    
                   
                      "@27.00": {
                        slidesPerView: 6.5,
                        spaceBetween: 20,
                    },
                    
                    
                }}
                modules={[Pagination]}
                className="mySwiper  "
            >


                <SwiperSlide className="swiperurunler mt-4" >

                    <Link to="/hizmetlerimiz/cncbukum" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz">
                            <Card.Img className='swiperImage' variant="top" src="/images/cnclazerkesim.png" />

                            <Card.Title className='cardTitle'>

                                CNC LAZER KESİM

                            </Card.Title>

                        </Card>
                    </Link>
                </SwiperSlide>


                <SwiperSlide className="swiperurunler  mt-4">
                    <Link to="/hizmetlerimiz/cncbukum" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/cncbukum.png" />
                            <Card.Title className='cardTitle' >

                                CNC BÜKÜM

                            </Card.Title>
                        </Card>
                    </Link>
                </SwiperSlide>


                <SwiperSlide className="swiperurunler mt-4 ">
                    <Link to="/hizmetlerimiz/profilkesim" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/profilkesim.png" />
                            <Card.Title className='cardTitle'>

                                PROFİL KESİM

                            </Card.Title>
                        </Card>
                    </Link>
                </SwiperSlide>
                <SwiperSlide className="swiperurunler  mt-4 ">
                    <Link to="/hizmetlerimiz/cnclazerkesim/demirdkpkesim" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/dkpsiyah.png" />
                            <Card.Title className='cardTitle'>

                                DEMİR-DKP KESİM
                            </Card.Title>
                        </Card>
                    </Link>
                </SwiperSlide>
                <SwiperSlide className="swiperurunler  mt-4">
                    <Link to="/hizmetlerimiz/cnclazerkesim/paslanmazkesim" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/paslanmazAnaMenu.png" />
                            <Card.Title className='cardTitle'>

                                PASLANMAZ KESİM

                            </Card.Title>

                        </Card>
                    </Link>
                </SwiperSlide>


                <SwiperSlide className="swiperurunler  mt-4">
                    <Link to="/hizmetlerimiz/cnclazerkesim/galvanizkesim" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/galveniz.png" />
                            <Card.Title className='cardTitle'>

                                GALVANİZ KESİM

                            </Card.Title>
                        </Card>
                    </Link>
                </SwiperSlide>
                <SwiperSlide className="swiperurunler mt-4">
                    <Link to="/hizmetlerimiz/cnclazerkesim/ozelmotifkesim" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz" >
                            <Card.Img className='swiperImage' variant="top" src="/images/motifKesimler.jpg" />
                            <Card.Title className='cardTitle'>

                                ÖZEL MOTİF KESİM

                            </Card.Title>
                        </Card>
                    </Link>
                </SwiperSlide>
                <SwiperSlide className="swiperurunler mt-3 " >

                    <Link to="/hizmetlerimiz/cncbukum" style={{ textDecoration: "none" }}>
                        <Card className="cardUrunlerimiz">
                            <Card.Img className='swiperImage' variant="top" src="/images/cnclazerkesim.png" />

                            <Card.Title className='cardTitle'>

                                CNC LAZER KESİM

                            </Card.Title>

                        </Card>
                    </Link>
                </SwiperSlide>
            </Swiper>

        </div>
    )
}

export default AnasayfaUrunler;