import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Projelerimiz.css";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import { Helmet } from 'react-helmet';
const Projelerimiz = () => {
    return (

        <>
            <Helmet>
                <title>ÜRETİMLERİMİZ - cnclazermetalkesim.com</title>
            </Helmet>
            <Carousel className="custom-carousel-slider1" indicators={false}>
                <Carousel.Item>
                    <img alt='üretimlerimiz cnc kesimler' className=' w-100' src="/images/uretimlerimizBanner.png" ></img>
                    <Carousel.Caption className='sliderüstüyazıKurumsalHizmetlerimiz d-flex'>
                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                            <p className='urteimlerimizYazi mt-5  fw-bold w3-animate-top'>
                                Üretimlerimiz
                            </p>
                        </div>
                    </Carousel.Caption>

                </Carousel.Item>
            </Carousel>

            <Container>
                <Row className='mt-3'>

                    <BreadCrumb />

                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", borderRadius: "10px", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='profil kesim ' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim1.jpg" />
                        </Card>
                    </Col>


                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", borderRadius: "10px", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='profil kesim ' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/profilkesim2.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum4.png" />
                        </Card>
                    </Col>

                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum3.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum5.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum1.png" />
                        </Card>
                    </Col>

                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum8.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>
                            <Card.Img alt='cnc büküm ' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum7.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum6.png" />
                        </Card>
                    </Col>
                    <Col sm={3} xs={6} className='mt-2 mb-2'>
                        <Card style={{ border: "none", boxShadow: "0px 2px 14px 0px   rgba(102, 204, 153, 0.8)", width: "100%", height: "100%" }} className='cardHizmetlerimiz '>

                            <Card.Img alt='cnc büküm' style={{ borderRadius: "10px", width: "100%", height: "100%" }} variant="top" src="/images/bukum9.png" />
                        </Card>
                    </Col>


                </Row>
            </Container>
        </>
    )
}

export default Projelerimiz;