import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import "./BreadCrumb.css"
const BreadCrumb = () => {
    const location = useLocation()
    const crumbs = location.pathname.split('/').filter(crumb => crumb !== '')

    return (


        <div className='breadCrumbs  mt-1 mb-3 ' >

            <div className='mobildBred d-flex  p-1' style={{ backgroundColor: "whitesmoke" , borderRadius:"10px"  , display:"flex" ,alignItems:"center"}} >
   
                <Link className='linkBreadCrumb fw-bold' to="/">Anasayfa </Link>
                
               / 
                {crumbs.map((crumb, index) => (

                    <div className='crumb' key={crumb}>
                        {index === crumbs.length - 1 ? (
                            <span>{crumb}</span>
                        ) : (
                            <div>

                                <Link className='linkBreadCrumb  fw-bold' to={`/${crumbs.slice(0, index + 1).join('/')}`}> {crumb}    </Link>

                                    /
                           
                            </div>



                        )}
                    </div>
                ))}
            </div>

        </div>


    )
}

export default BreadCrumb;
